const utmTags = [`utm_source`, `utm_medium`, `utm_campaign`, `utm_content`, `utm_term`];
const urlParams = new URLSearchParams(window.location.search);
const queryParamsArray = utmTags
    .map((utmTag) => {
        const utmValue = urlParams.get(utmTag);
        if (!utmValue) {
            return undefined;
        }

        return `${utmTag}=${utmValue}`;
    })
    .filter(Boolean);

const navLinks = document.querySelectorAll(`a`);

navLinks.forEach((navLink) => {
    if (queryParamsArray.length < 1 || navLink.href.includes(`#`)) {
        return;
    }

    if (navLink.href.includes(`?`)) {
        navLink.href += `&${queryParamsArray.join(`&`)}`;
        return;
    }

    navLink.href += `?${queryParamsArray.join(`&`)}`;
});
